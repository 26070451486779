.footer__content-block form {
    width: 100%;
    label {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }
    input, textarea {
        transition: all 0.15s ease-out;
    }
    input[type="submit"] {
        background-color: $yellow;
        background-image: linear-gradient(to top, $yellow 0%, lighten($yellow, 15) 100%);
        color: $blue;
        padding: 0.5em 1.5em;
        outline: none;
        border: 1px solid darken($yellow, 10);
        box-shadow: 0 1px 4px rgba($black, 0.35);
        display: block;
        margin-left: auto;
        @media (max-width: 400px) {
            width: 100%;
        }
        &:focus {
            border-color: lighten($blue, 25);
            box-shadow: 0 0 6px rgba(lighten($blue, 15), 0.85);
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
        &:hover {
            box-shadow: 0 2px 6px rgba($black, 0.35);
        }
    }
    input:not([type="submit"]),
    textarea {
        width: 100%;
        background-color: $darker_gray;
        outline: none;
        border: 1px solid $darker_gray;
        padding: 0.5em;
        box-shadow: 1px 1px 3px rgba($black, 0.35);
        &:focus {
            box-shadow: 0 0 6px rgba($yellow, 0.75);
            border-color: $yellow;
        }
    }
    .form__name,
    .form__email {
        label {
            justify-content: space-between;
            .label__text {
                flex: 0 0 50px;
            }
            .wpcf7-form-control-wrap {
                flex-grow: 1;
                // margin-left: 1em;
            }
        }
    }

    .form__message {
        label {
            flex-wrap: wrap;
            span {
                flex: 1 0 100%;
            }
        }
        .label__text {
            margin-bottom: 0.5em;
        }
    }
}