.single-product {
    .product_title {
        line-height: 1.3;
    }
    .woocommerce-product-gallery {
        margin-top: 1em;
    }
    @media (max-width: 650px) {
        .product_title {
            font-size: 1.5em;
        }
        .woocommerce-product-gallery {
            width: 40%;
        }
        .entry-summary {
            width: 60%;
        }
    }
    @media (max-width: 500px) {
        .woocommerce-product-gallery {
            width: 100%;
        }
        .entry-summary {
            width: 100%;
        }
    }
}