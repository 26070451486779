body {
    padding-top: 136px;
    @media(max-width: $screen-sm) {
        padding-top: 75px;
    }
}


.content-wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
}

.global-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

img {
    max-width: 100%;
    height: auto;
}


a {
    transition: all 0.15s ease-out;
}

.btn {
    // padding: 1em 3em;
    text-transform: uppercase;
    &.btn-primary {
        &:hover {
            background-color: $blue;
            color: $white;
            box-shadow: 0 1px 6px rgba($black, 0.25);
        }
    }
    &.btn-lg {
        padding: 0.75em 2.5em;
    }
}

.date {
    font-family: 'Questrial', sans-serif;
    color: $yellow;
}

.archive-post {
    .tease {
        margin-bottom: 1em;
    }
}

.color-bar-wrapper{
	display: flex;
}

.left-color-bar{
	height: 10px;
	background-color: $vital_blue;
	flex:1 0 50%;
}

.right-color-bar{
	height: 10px;
	background-color: $vital_yellow;
	flex: 1 0 50%;
}

.page-content {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}