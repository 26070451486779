.footer-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:before,
    &:after {
        display: none;
    }
    @media (max-width: $screen-md) {
        flex-wrap: wrap;
        > * {
            flex: 1 0 100%;
        }
    }
}


.footer-bar__menu {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
    @media (max-width: $screen-md) {
        justify-content: center;
        order: -1;
        flex-wrap: wrap;
    }
}

.footer-bar__menu__link {
    list-style-type: none;
    a {
        padding: 1em;
        display: block;
        white-space: nowrap;
        &:hover {
            text-decoration: none;
        }
    }
    &.current_page_item a {
        font-weight: 900;
        // color: $blue;
    }
}

.footer-bar__legal {
    font-size: 0.8em;
    @media (max-width: $screen-md) {
        text-align: center;
        padding: 1em;
    }
    p {
        margin: 0;
    }
}