.hero {

}

$overlap_amount: 25px;
$sm_overlap_amount: 50px;

.hero__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    // background-attachment: fixed;
    min-height: 500px;
    background-color: #393837;
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    overflow: hidden;

    @media (max-width: $screen-md) {
        // overflow: visible;
        min-height: 400px;
        // background-attachment: inherit;
        // min-height: 500px;
    }
    @media (max-width: $screen-sm) {
        min-height: 250px;
        .container {
            padding: 0 0.5em 0.5em;
        }
    }
    @media (max-width: 400px) {
        min-height: 175px;
    }
}

.hero__content {
    box-shadow: 0 -3px 16px rgba($black, 0.35);
    padding: 1em 4em;
    color: $white;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(to bottom, rgba($black, 1) 0%, rgba($black, 0) 100%);
    h1, h2, h3, h4, h5, h6 {
        color: $white;
        margin-top: 0;
        text-shadow: 1px 1px 3px rgba($black, 0.35);
        line-height: 1.3;
    }
    @media (max-width: $screen-md) {
        background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.2) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
        background-image: linear-gradient(to bottom, rgba($black, 1) 0%, rgba($black, 0.2) 100%);
        padding: 1em;
        // transform: translateY($overlap_amount);
        border: 1px solid $yellow;
    }
    @media (max-width: $screen-sm) {
        // transform: translateY($sm_overlap_amount);
        padding: 0.75em;
        font-size: 0.9em;
    }
}



