.navbar {
    box-shadow: 0 2px 40px rgba($black, 0.4);
    margin-bottom: 0;
    border: none;
    .container {
        position: relative;
    }
    .navbar-toggle {
        &:not(.collapsed) {
            background-color: $light_blue;
            .icon-bar {
                background-color: $white;
            }
            &:focus {
                background-color: $light_blue;
            }
        }
        &:focus {
            background-color: $white;
        }
    }
    @media(max-width: $screen-sm) {
        min-height: 75px;
        .navbar-toggle {
            margin-top: 23px;
            margin-bottom: 23px;
        }
    }
}


.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    .logo {
        width: 250px;
        // width: 100%;
        height: 100%;
        background-image: url('static/img/logos/vc_logo_no-tag.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        // @include media('retina2x') {
        //     background-image: url('static/img/logos/vital_competition_logo@2x.png');
        // }
        @media(max-width: 400px) {
            width: 200px;
        }
    }
    @media(max-width: $screen-sm) {
        height: 75px;
        // margin-top: 0.5em;
        // margin-bottom: 0.5em;
    }
}

.navbar-nav {
	// float: right;
    a {
        // font-family: $font__header;
        font-size: 1.25em;
        text-transform: uppercase;
    }
    .current_page_item a {
        font-weight: 900;
        color: $blue;
    }
}
