.footer__dark {
    background-color: $dark_gray;
    background-image: url('static/img/images/concrete_seamless_gray.png');
    background-attachment: fixed;
    color: $white;
    padding-top: 1em;
    &:before {
    }
    h1, h2, h3, h4, h5, h6 {
        color: $white;
        text-shadow: 1px 1px 3px rgba($black, 0.35);
    }
}

.footer__logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.footer__logo {
    margin: 2em;
    height: auto;
    flex: 0 0 25%;
    max-width: 25%;
    @media (max-width: $screen-sm) {
        max-width: 90%;
        flex: 0 0 90%;
    }
}


.footer__content-blocks {
    display: flex;
    justify-content: center;
    a {
        color: $yellow;
    }
    // .footer__content-block:nth-of-type(odd) {
    //     flex: 0 0 35%;
    //     padding-right: 1em;
    // }
    // .footer__content-block:nth-of-type(even) {
    //     flex: 1 0 65%;
    // }
    .footer__content-block {
        flex: 1 0 content;
    }
    @media(max-width: 700px) {
        flex-wrap: wrap;
        .footer__content-block:nth-of-type(odd) {
            text-align: center;
            padding-right: 0;
        }
        .footer__content-block {
            flex: 1 0 100% !important;
        }
    }
}

