.social-links {
    display: flex;
    align-items: center;
    margin-left: 1em;
    li {
        margin-right: 1em;
        &:last-child {
            margin-right: 0;
        }
    }
    a {
        display: block;
        height: 100%;
        text-indent: -9999px;
        width: 36px;
        background-size: auto 30px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: all 0.25s ease-out;
        &.instagram {
            background-image: url('static/img/icons/blue/instagram.png');
        }
        &.facebook {
            background-image: url('static/img/icons/blue/facebook.png');
        }
        &.twitter {
            background-image: url('static/img/icons/blue/twitter.png');
        }
        &:hover,
        &:focus {
            background-color: transparent !important;
            transform: scale(1.1);
            &.instagram {
                background-image: url('static/img/icons/yellow/instagram.png');
            }
            &.facebook {
                background-image: url('static/img/icons/yellow/facebook.png');
            }
            &.twitter {
                background-image: url('static/img/icons/yellow/twitter.png');
            }
        }
    }
}

.social-links.footer {
    margin: 2em 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-right: 2em;
        &:last-child {
            margin-right: 0;
        }
    }
    a {
        display: block;
        width: 70px;
        height: 70px;
        border-radius: 6px;
        background-color: $darker_gray;
        box-shadow: 0 1px 4px rgba($black, 0.3);
        border: 1px solid $yellow;
        &.instagram {
            background-image: url('static/img/icons/yellow/instagram.png');
        }
        &.facebook {
            background-image: url('static/img/icons/yellow/facebook.png');
        }
        &.twitter {
            background-image: url('static/img/icons/yellow/twitter.png');
        }
        &:hover,
        &:focus {
            background-color: $darker_gray !important;
            transform: scale(1.1);
            border-color: $gray;
            &.instagram {
                background-image: url('static/img/icons/gray/instagram.png');
            }
            &.facebook {
                background-image: url('static/img/icons/gray/facebook.png');
            }
            &.twitter {
                background-image: url('static/img/icons/gray/twitter.png');
            }
        }
    }
}

