@import 'wc_single_product';


.cart_item {
    .quantity {
        float: none;
        margin-right: 0;
    }
}

.vc-myaccount-navigation{
	
	float: left;
	width:30%;
	
	//none
}

.vc-myaccount-navigation ul{
	list-style: none;
}

.shop-thumbnail{
	max-width: 220px!important;
	min-width:1px;
	
}

.h2-short{
	margin-top:0px!important;
    margin-bottom:0px!important;
    line-height: 1.3;
}

.flex-product {
    .flex-content-first {
        flex: 0 0 30%;
    }
    .flex-content-second {
        padding-bottom: 1em;
        text-align: left;
        flex: 1 0 60%;
    }
    @media (max-width: 550px) {
        flex-wrap: wrap;
        .flex-content-first,
        .flex-content-second {
            flex: 1 0 100%;
            text-align: center;

        }
    }
}

.woocommerce-product-gallery{
	float: left;
	width: 48%;
}

.woocommerce-product-gallery__wrapper{
	margin-right: 3px;
}

.woocommerce-product-gallery ol{
	list-style: decimal;
	margin: 0 0 1.5em;
	padding: 0;
	box-sizing: inherit;
}


.woocommerce-product-gallery--columns-4 .flexcontrol-thumbs li:nth-child{
	clear: left;
}

.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
    width: 25%;
}

.woocommerce-product-gallery .flex-control-thumbs li {
    list-style: none;
    float: left;
    cursor: pointer;
}


.woocommerce-product-gallery__trigger {
    display: none;
}



.entry-summary{
	float: right;
	width: 48%;
	text-align: left;
}

.entry-flex{
	display: flex;
}

.woocommerce-Price-amount{
	font-size: x-large;
}

.quantity{
	float: left;
	margin-right: 5px;
}


// .woocommerce button.button.alt:hover
.woocommerce button.button.alt,
.woocommerce a.button.alt {
    background-color: black!important;
    transition: all 0.15s ease-out;
    &:hover {
        background-color: $yellow !important;
        color: $black !important;
        box-shadow: 0 3px 4px rgba($black, 0.3);
    }
}


.woocommerce input.button {
    transition: all 0.15s ease-out;
    &:not(:disabled) {
        background-color: $black;
        color: $white;
    }
}


form.checkout {
    padding-top: 6em;
}

.wc-tabs {
    display: none;
}

.woocommerce-Tabs-panel--additional_information > h2 {
    display: none;
}
