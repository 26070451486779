



.tease-header {
    padding-right: 5em;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 4em auto;
    &.post {
        background-image: url('static/img/icons/blue/newspaper@2x.png');
    }
}


.tease-date {
    display: block;
    margin-bottom: 1em;
}


.flex-product{
	display: flex;
	border-bottom:1px solid #ccc;
	margin-bottom:15px;
}

.flex-content-first{
	flex: 1 0 50%;
}

.flex-content-second{
	flex: 1 0 50%;
}

.flexo{
	//nothing
}